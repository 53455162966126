import React from "react";

const SaleContext = React.createContext();

function useSalesContext() {
    const context = React.useContext(SaleContext);
    if (!context) {
      throw new Error(
        "useAppContext must be used within an AppContext.Provider"
      );
    }
    return context;
}
  
export default SaleContext;

function SaleProvider({ children }) {
  const [addedItems, setAddedItems] = React.useState([]);
  const [selectedPatient, setSelectedPatient] = React.useState(null);
  const [patientPhone, setPatientPhone] = React.useState("OTC");
  const [old_or_new, setOldOrNew] = React.useState("new");

  const value = {
    addedItems,
    setAddedItems,
    selectedPatient,
    setSelectedPatient,
    patientPhone,
    setPatientPhone,
    old_or_new,
    setOldOrNew
  };

  return <SaleContext.Provider value={value}>{children}</SaleContext.Provider>;
}

export { SaleProvider, useSalesContext };

