import "../styles/globals.css";
import "material-icons/iconfont/material-icons.css";
import { Lexend } from "next/font/google";
import Head from "next/head";
import { MantineProvider } from "@mantine/core";
import { SessionProvider, useSession } from "next-auth/react";
import NextNProgress from "nextjs-progressbar";
import { Notifications } from "@mantine/notifications";
import { SaleProvider } from "context/SalesState";
import { useEffect } from "react";
import { Provider, ErrorBoundary, LEVEL_ERROR, LEVEL_DEBUG } from "@rollbar/react";

// const ibm_plex_sans = Inter({ weight: '500', preload: false, subsets: ['latin']})
const lexend = Lexend({
  variable: "--font-lexend",
  subsets: ["latin"],
});

const rollbarConfig = {
  accessToken: "798127bd6ffb469ba077638de41e5e9f",
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: "production",
  captureIp: true,
  captureDeviceInfo: true,
  enabled: process.env.NODE_ENV === "production",
};

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  if (process.env.NODE_ENV !== "development") {
  }
  return (
    <SessionProvider session={session}>
      <NextNProgress />
      <Head>
        <title>neulink</title>
        {/* <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" /> */}
        <link rel="icon" href="/512.png" />
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable=no"
        />
        <meta name="theme-color" content="#000C38" />
      </Head>

      <Provider config={rollbarConfig}>
        <ErrorBoundary level={LEVEL_DEBUG}>
          <MantineProvider withGlobalStyles withNormalizeCSS>
            <main className={lexend.className}>
              <Notifications position="top-center" />
              <SaleProvider>
                <Component {...pageProps} />
              </SaleProvider>
            </main>
          </MantineProvider>
        </ErrorBoundary>
      </Provider>
    </SessionProvider>
  );
}

export default MyApp;
